import {useCallback, useEffect, useMemo} from 'react';
import isEqual from 'react-fast-compare';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {FILTERS, NO_SEARCH_FILTERS_PARAMS} from 'job-board/shared/components/searchFilters/constants';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {useUserData} from 'job-board/shared/redux/userData/hooks';
import {buildUrlSearchParams} from 'job-board/shared/utils/urlSearchParams';
import useSearchRouteUrlParams from '../useSearchRouteUrlParams';
import {clearWorkplaceUrlParam, getInitialFiltersBasedOnUrl, shouldClearWorkplaceUrlParam} from './helpers';

type SearchFilterValue = string | string[] | null | undefined;

type SearchJobsFilters = {
  searchFilters: Record<string, string | string[]> | Record<string, never>;
  updateSearchFilters: (params: Record<string, SearchFilterValue>, options?: {shouldScrollToTop?: boolean}) => void;
  urlSearchParams: URLSearchParams;
};

const useSearchJobsFilters = (): SearchJobsFilters => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParams = useSearchRouteUrlParams();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const {data: userData, isUninitialized: isUserDataLoading} = useUserData();

  const searchFilters = FILTERS.reduce<Record<string, string | string[]>>((filters, filter) => {
    const filterValues = searchParams.getAll(filter.id);

    if (!filterValues.length) {
      return filters;
    }

    return {
      ...filters,
      [filter.id]: filter.multiSelect ? filterValues : filterValues[0]
    };
  }, getInitialFiltersBasedOnUrl(urlParams));

  useEffect(() => {
    if (isUserDataLoading) {
      return;
    }

    const isForYouOptionEnabled = FEATURE_FLAGS.IS_PERSONALIZATION_ENABLED && !!userData?.isCompleteProfile;

    const isForYouOptionSelected = searchParams.get('filterByProfile') === 'true';

    if (!isForYouOptionEnabled && isForYouOptionSelected) {
      const currentSearchParams = new URLSearchParams(window.location.search);

      currentSearchParams.delete('filterByProfile');

      navigate({
        search: currentSearchParams.toString()
      });
    }
  }, [isUserDataLoading, navigate, userData, searchParams]);

  // The JSON.stringify hack was used here because the stability of the reference of `searchFilters`
  // is based on the `searchParams` whose reference would change every time a query param value
  // would change, even if that query param isn't included in the `searchFilters`.
  const memoizedSearchFilters = useMemo(() => searchFilters, [JSON.stringify(searchFilters)]);

  const updateSearchFilters = useCallback<SearchJobsFilters['updateSearchFilters']>(
    (newSearchFilters, {shouldScrollToTop = true} = {}) => {
      if ('workplace' in newSearchFilters) {
        const workplaceFilter = newSearchFilters.workplace as string[] | undefined;
        const shouldClearAllFilters = isEqual(newSearchFilters, NO_SEARCH_FILTERS_PARAMS);

        if (shouldClearWorkplaceUrlParam(urlParams, 'remote', workplaceFilter)) {
          return clearWorkplaceUrlParam({
            currentLocation,
            navigate,
            paramName: 'remote',
            shouldClearAllSearchParams: shouldClearAllFilters
          });
        }
        if (shouldClearWorkplaceUrlParam(urlParams, 'hybrid', workplaceFilter)) {
          return clearWorkplaceUrlParam({
            currentLocation,
            navigate,
            paramName: 'hybrid',
            shouldClearAllSearchParams: shouldClearAllFilters
          });
        }
      }

      setSearchParams(buildUrlSearchParams({...newSearchFilters, selectedJobId: ''}, searchParams));

      if (shouldScrollToTop) {
        window.scrollTo({behavior: 'smooth', top: 0});
      }
    },
    [searchParams, urlParams, currentLocation]
  );

  return {
    searchFilters: memoizedSearchFilters,
    updateSearchFilters,
    urlSearchParams: searchParams
  };
};

export default useSearchJobsFilters;
