import type {Location, NavigateFunction} from 'react-router-dom';
import type {SearchRouteUrlParams} from '../useSearchRouteUrlParams';

export const getInitialFiltersBasedOnUrl = (
  urlParams: SearchRouteUrlParams
): Record<string, string[]> | Record<string, never> => {
  if (urlParams.hybrid) {
    return {workplace: ['hybrid']};
  }

  if (urlParams.remote) {
    return {workplace: ['remote']};
  }

  return {};
};

export const shouldClearWorkplaceUrlParam = (
  urlParams: SearchRouteUrlParams,
  paramName: 'hybrid' | 'remote',
  newWorkplaceFilterValues: string[] | undefined
): boolean => urlParams[paramName] && !newWorkplaceFilterValues?.includes(paramName);

type ClearWorkplaceUrlParamPayload = {
  currentLocation: Location;
  navigate: NavigateFunction;
  paramName: 'hybrid' | 'remote';
  shouldClearAllSearchParams?: boolean;
};

export const clearWorkplaceUrlParam = ({
  currentLocation,
  navigate,
  paramName,
  shouldClearAllSearchParams
}: ClearWorkplaceUrlParamPayload): void => {
  const newPathname =
    paramName === 'remote'
      ? currentLocation.pathname.replace('remote-', '').replace('work-from-home-', '')
      : currentLocation.pathname.replace('hybrid-', '');
  const newSearch = shouldClearAllSearchParams ? '' : currentLocation.search.replace(`workplace=${paramName}`, '');

  navigate({
    pathname: newPathname,
    search: newSearch
  });
};
