import React, {createContext, type ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import usePrevious from 'shared/hooks/usePrevious';

export type HasRouteChangedContextType = boolean | undefined;
export const HasRouteChangedContext = createContext<HasRouteChangedContextType>(undefined);

export const HasRouteChangedProvider = ({children}: {children: ReactNode}): JSX.Element => {
  const location = useLocation();
  const previousLocation = usePrevious(location);

  const hasRouteChanged = !!previousLocation && previousLocation !== location;

  return <HasRouteChangedContext.Provider value={hasRouteChanged}>{children}</HasRouteChangedContext.Provider>;
};
