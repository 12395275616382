import {useLayoutEffect, useRef} from 'react';
import {matchPath} from 'react-router';
import {useLocation} from 'react-router-dom';
import noop from 'lodash/noop';
import {useCompanyAttributionMutation, useJobAttributionMutation} from 'job-board/api/jobBoardApi';
import {routesPaths} from 'job-board/routes/constants';

const Attributions = (): null => {
  const location = useLocation();
  const previousLocation = useRef<string>();
  const [jobAttribution] = useJobAttributionMutation();
  const [companyAttribution] = useCompanyAttributionMutation();

  useLayoutEffect(() => {
    window.scrollTo({top: 0});

    // We make the attribution call ONLY when the user visits the job/company page through the app because when the page
    // is requested and loaded from the server, the back-end makes the attribution call instead.
    if (previousLocation.current) {
      const jobRouteMatch = matchPath(`${routesPaths.JOB}/:jobId/*`, location.pathname);
      const companyRouteMatch = matchPath(`${routesPaths.COMPANY}/:companyId/*`, location.pathname);

      if (jobRouteMatch?.params?.jobId) {
        jobAttribution({id: jobRouteMatch.params.jobId, position: null}).catch(noop);
      } else if (companyRouteMatch?.params?.companyId) {
        companyAttribution(companyRouteMatch.params.companyId).catch(noop);
      }
    }

    previousLocation.current = location.pathname;
  }, [location.pathname]);

  return null;
};

export default Attributions;
