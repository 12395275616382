import {deepFreeze} from 'shared/helpers/deepFreeze';

export const FILTERS = deepFreeze([
  {
    id: 'day_range',
    multiSelect: false,
    options: [{value: '1'}, {value: '7'}, {value: '30'}, {value: '0'}]
  },
  {
    id: 'workplace',
    multiSelect: true,
    options: [{value: 'on_site'}, {value: 'hybrid'}, {value: 'remote'}]
  },
  {
    id: 'employment_type',
    multiSelect: true,
    options: [{value: 'full_time'}, {value: 'part_time'}, {value: 'contract'}, {value: 'temporary'}, {value: 'other'}]
  },
  {
    id: 'experience',
    multiSelect: true,
    options: [
      {value: 'internship'},
      {value: 'entry_level'},
      {value: 'associate'},
      {value: 'mid_senior_level'},
      {value: 'director'},
      {value: 'executive'}
    ]
  }
]);

export const NO_SEARCH_FILTERS_PARAMS = deepFreeze(
  FILTERS.reduce(
    (noFiltersParams, filter) => ({
      ...noFiltersParams,
      [filter.id]: undefined
    }),
    {}
  )
);
