import React, {type PropsWithChildren} from 'react';
import Theme, {type ThemeProps} from 'shared/ui/atoms/theme';
import {contrastAccessibleColors} from 'shared/ui/atoms/theme/themes';
import color from '../../../../../shared/ui/css/functions/color.scss';

const computedStyle = window.getComputedStyle(window.document?.documentElement);

const DEFAULT_COLOR = computedStyle?.getPropertyValue('--color-default')?.trim() || color.green500;

const FOOTER_TEXT_COLOR = computedStyle?.getPropertyValue('--color-footer-text')?.trim() || color.white;

const POPULAR_SEARCH_TEXT_COLOR = computedStyle?.getPropertyValue('--color-popular-search-text')?.trim() || color.white;

const WORKABLE_COLOR = computedStyle?.getPropertyValue('--color-workable')?.trim() || color.green500;

export const DEFAULT_THEME = {
  ...contrastAccessibleColors,
  'color-cookie-consent': DEFAULT_COLOR,
  'color-default': DEFAULT_COLOR,
  'color-footer-text': FOOTER_TEXT_COLOR,
  'color-popular-search-text': POPULAR_SEARCH_TEXT_COLOR,
  'color-workable': WORKABLE_COLOR
};

const ThemeProvider = ({
  children,
  theme = DEFAULT_THEME
}: PropsWithChildren<{theme?: ThemeProps['theme']}>): JSX.Element => <Theme theme={theme}>{children}</Theme>;

export default ThemeProvider;
