export const FEATURE_FLAGS = {
  IS_ATLAS_ENABLED: window.jobBoard.featureFlags.wjb_atlas_enabled ?? false,
  IS_COMPLETE_PROFILE_PROMPT_ENABLED: window.jobBoard.featureFlags.wjb_complete_profile_prompt_enabled ?? false,
  IS_GOOGLE_DRIVE_UPLOAD_ENABLED: window.jobBoard.featureFlags.wjb_google_drive_upload_enabled ?? false,
  get IS_PERSONALIZATION_ENABLED(): boolean {
    return window.jobBoard.featureFlags.wjb_personalization_enabled ?? false;
  },
  set IS_PERSONALIZATION_ENABLED(isEnabled: boolean) {
    window.jobBoard.featureFlags.wjb_personalization_enabled = isEnabled;
  },
  IS_PROFILE_ENABLED: ((): boolean =>
    window.location.search.toLowerCase().includes('profileenabled=true') ||
    (window.jobBoard.featureFlags.candidateProfileEnabled ?? false))(),
  IS_TURNSTILE_ENABLED: window.jobBoard.featureFlags.wjb_acp_turnstile_captcha_enabled ?? false
};
