import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import search16 from 'assets/svg/search16.svg?sprite';
import clsx from 'clsx';
import TextBody from 'shared/ui/atoms/text/body';
import EllipsisWithTooltip from 'shared/ui/molecules/ellipsisWithTooltip';
import {jobBoardApi} from 'job-board/api/jobBoardApi';
import {useSelector} from 'job-board/redux';
import Svg from 'job-board/shared/components/svg';
import useSearchJobsFilters from 'job-board/shared/hooks/useSearchJobsFilters';
import useSearchJobsParams from 'job-board/shared/hooks/useSearchJobsParams';
import {ForYouPill} from '../forYou';
import styles from './searchTrigger.scss';

type SearchTriggerProps = {
  className?: string;
  companyName?: string;
  onSearchButtonClick: () => void;
  placeholder?: string;
};

const TOOLTIP_PROPS = Object.freeze({});

const Query = ({isForYouOptionSelected, query}: {isForYouOptionSelected: boolean; query: string}): JSX.Element => {
  if (isForYouOptionSelected) {
    return <ForYouPill className={styles['for-you']} />;
  }

  return <TextBody>{query}</TextBody>;
};

export const SearchTrigger = ({
  className,
  companyName,
  onSearchButtonClick,
  placeholder
}: SearchTriggerProps): JSX.Element => {
  const {t} = useTranslation();
  const {filterByProfile, flag, location, query, shouldDetectLocation} = useSearchJobsParams();
  const {searchFilters} = useSearchJobsFilters();

  const requestParams = useMemo(
    () => ({
      detectLocation: shouldDetectLocation,
      filterByProfile,
      flag,
      location,
      query,
      ...searchFilters
    }),
    [filterByProfile, flag, location, query, searchFilters, shouldDetectLocation]
  );

  const selectJobs = useMemo(() => jobBoardApi.endpoints.getPaginatedJobs.select(requestParams), [requestParams]);

  const {data: searchResults} = useSelector(selectJobs);

  const hasSearchTerms = !!query || !!location || !!filterByProfile;
  const isForYouOptionSelected = !!filterByProfile;
  const resolvedLocation = (shouldDetectLocation && searchResults?.autoAppliedFilters.location) || location;

  return (
    <div className={clsx(styles.container, className)}>
      <button className={styles.button} data-ui="search-for-jobs-button" onClick={onSearchButtonClick}>
        <Svg className={styles.icon} id={search16.id} />
        <EllipsisWithTooltip tooltipProps={TOOLTIP_PROPS}>
          <span className={styles.text}>
            {hasSearchTerms ? (
              <>
                {(!!query || isForYouOptionSelected) && (
                  <Query isForYouOptionSelected={isForYouOptionSelected} query={query} />
                )}
                {!!companyName && <TextBody>{companyName}</TextBody>}
                {!!resolvedLocation && <TextBody>{resolvedLocation}</TextBody>}
              </>
            ) : (
              <TextBody secondary>{placeholder ?? t('shared.search-input.job-title.placeholder')}</TextBody>
            )}
          </span>
        </EllipsisWithTooltip>
      </button>
    </div>
  );
};
